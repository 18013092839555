import React, { useEffect, useState } from 'react';
import IconRenderer from 'hoc/IconRenderer';
// utils

// store
import { useAppStore, useWalletStore } from 'store';

//locales
import { useTranslation } from 'react-i18next';

// types
import {
  EInputSizeType,
  EButtonSizeType,
  EButtonTypeType,
  ESelectSizeType,
} from 'types/units';

//hooks
import {
  useQueryFetchUserBalance,
  useQueryFetchRefillCountries,
  useMutationGetRefillMethods,
  useMutationSendRefill,
} from '../../../../hooks/api';

// components
import { Button, Input, Select } from 'antd';
import { ArrowDownIcon } from 'components/icons';

// styles
import './index.scss';

// data
import { countryList, cardList, formConfig } from './mockdata';
import { formatPartsPrice } from '../../../../utils/commons';
import { ERefillResponseType, ERefillTaxType } from 'types/models';
import { toast } from 'react-toastify';

const { Option } = Select;
const AddBalanceForm = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();
  const { setRefillPaymentForm } = useWalletStore();

  const [currentCountry, setCurrentCountry] = useState<any>(null);

  const [currentCard, setCurrentCard] = useState<{
    [key: string]: any;
  } | null>(null);

  const [refillPayload, setRefillPayload] = useState<any>(null);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [refillAmount, setRefillAmount] = useState<any>('');

  const { data: userBalance, isLoading: isLoadingUserBalance } =
    useQueryFetchUserBalance(false);

  const { data: refillCountriesData, isLoading: isLoadingRefillCountries } =
    useQueryFetchRefillCountries();
  const {
    data: refillMethods,
    isLoading: isLoadingRefillMethods,
    mutate: mutateRefillMethods,
  } = useMutationGetRefillMethods();
  const {
    data: sendRefillData,
    isLoading: isLoadingSendRefill,
    mutate: mutateSendRefill,
  } = useMutationSendRefill();

  useEffect(() => {
    if (refillCountriesData && !isLoadingRefillCountries) {
      setCurrentCountry(refillCountriesData[0].id);
    }
  }, [refillCountriesData, isLoadingRefillCountries]);

  useEffect(() => {
    if (currentCountry) {
      mutateRefillMethods(currentCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  useEffect(() => {
    if (refillMethods && !isLoadingRefillMethods) {
      setCurrentCard(refillMethods[0]);
    }
  }, [refillMethods, isLoadingRefillMethods]);

  useEffect(() => {
    if (currentCard) {
      let { formData } = currentCard;
      if (formData?.length) {
        setRefillPayload(
          formData.map(el => {
            return { ...el, value: '' };
          }),
        );
      } else {
        setRefillPayload(null);
      }
    }
  }, [currentCard]);

  const onConfirm = () => {
    const payload = {
      amount: refillAmount,
      code: currentCountry,
      currency: currentCard?.id,
      payload: refillPayload?.reduce((result, item) => {
        if (item.name && 'value' in item) {
          result[item.name] = item.value;
        }
        return result;
      }, {}),
    };
    mutateSendRefill(payload);
    // sModalClose(id);
    // sModalOpen('withdrawConfirmation');
  };

  useEffect(() => {
    if (sendRefillData && !isLoadingSendRefill) {
      if (sendRefillData.type === ERefillResponseType.URL) {
        if (sendRefillData.redirectUrl?.length) {
          window.location.href = sendRefillData.redirectUrl;
        } else {
          toast.error(t('Something went wrong'));
        }
      }
      if (sendRefillData.type === ERefillResponseType.JSON) {
        setRefillPaymentForm(sendRefillData.data);
        sModalOpen('refillPayment');
        sModalClose(modalId);
      }
      if (sendRefillData.type === ERefillResponseType.HTML) {
        //TODO HTML Render
        setRefillPaymentForm(sendRefillData.html);
        // sModalOpen('refillPayment');
        sModalClose(modalId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRefillData, isLoadingSendRefill]);
  const onChangeCountry = (value: string) => {
    setCurrentCountry(value);
  };
  const onChooseCard = card => {
    if (currentCard?.id !== card.id) {
      setCurrentCard(card);
    }
  };
  const handleChangeFormInput = (index, e) => {
    const newValues = [...refillPayload];
    newValues[index].value = e.target.value;
    setRefillPayload(newValues);
  };
  const handleChangeAmount = e => {
    setRefillAmount(e.target.value.replace(/[^0-9.]/g, ''));
  };

  useEffect(() => {
    if (refillPayload?.length) {
      if (currentCard?.minimalAmount && currentCard?.minimalAmount > 0) {
        if (
          refillAmount &&
          Number(refillAmount) >= currentCard?.minimalAmount
        ) {
          setDisabledSubmit(
            !refillPayload.every(el => el.required && el.value.length),
          );
        } else {
          setDisabledSubmit(true);
        }
      } else {
        if (refillAmount) {
          setDisabledSubmit(
            !refillPayload.every(el => el.required && el.value.length),
          );
        } else {
          setDisabledSubmit(true);
        }
      }
    } else {
      refillAmount ? setDisabledSubmit(false) : setDisabledSubmit(true);
    }
  }, [refillPayload, refillAmount, currentCard]);

  return (
    <div className="withdraw-balance-form">
      <div className="withdraw-balance-form__grid">
        <div className="withdraw-balance-form__aside">
          <div className="withdraw-balance-form__main--title mobile">
            {t('increaseFunds')}
          </div>
          <div className="withdraw-balance-form__main-countries">
            <Select
              className={'ant-select--light ant-select--line'}
              size={ESelectSizeType.Middle}
              suffixIcon={<ArrowDownIcon />}
              value={currentCountry}
              onChange={onChangeCountry}
              dropdownAlign={{ offset: [0, 0] }}
              popupClassName={'withdraw-balance-form__main-countries__dropdown'}
            >
              {refillCountriesData?.length &&
                refillCountriesData.map(country => {
                  return (
                    <Option
                      value={country.id}
                      label={country.label}
                      key={country.id}
                    >
                      <span
                        role="img"
                        className="lang-toggler__option-flag"
                        aria-label={country.label}
                      >
                        {country.flag}
                      </span>
                      {country.label}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="withdraw-balance-form__aside--list">
            {refillMethods?.length &&
              !isLoadingRefillMethods &&
              refillMethods.map(card => {
                return (
                  <div
                    className={
                      'withdraw-balance-form__aside--list-item' +
                      (card.id === currentCard?.id
                        ? ' withdraw-balance-form__aside--list-item--active'
                        : '')
                    }
                    onClick={() => onChooseCard(card)}
                    key={card.id}
                  >
                    <div className="withdraw-balance-form__aside--list-item-label">
                      {card.icon ? (
                        <img src={card.icon} alt={card.icon} />
                      ) : (
                        <></>
                      )}
                      <span>{card.label}</span>
                    </div>
                    <div className="withdraw-balance-form__aside--list-item-value">
                      {card.tax}
                      {card.taxType === ERefillTaxType.Percentage ? '%' : '$'}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="withdraw-balance-form__main">
          <div className="withdraw-balance-form__main--title desktop">
            {t('increaseFunds')}
          </div>
          <div className="withdraw-balance-form__main--balance">
            {t('yourBalance')}: ${' '}
            <span>
              {formatPartsPrice(userBalance?.amount).significantDigit}
            </span>
            .{formatPartsPrice(userBalance?.amount).fractionDigit}
          </div>
          <div className="withdraw-balance-form__main--form">
            {refillPayload?.length &&
              refillPayload.map((input, index) => {
                return (
                  <div
                    key={input.name}
                    className="withdraw-balance-form__main--form-item"
                  >
                    <div className="withdraw-balance-form__main--form-item-label">
                      {input.label}
                    </div>
                    <Input
                      required={input.required}
                      className={'ant-input--borderless'}
                      size={EInputSizeType.Large}
                      name={input.name}
                      type={input.type}
                      onChange={e => handleChangeFormInput(index, e)}
                      placeholder={input.placeholder}
                    />
                  </div>
                );
              })}
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                {t(`refillForm.input.amount`)}{' '}
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                name={'amount'}
                type={'number'}
                value={refillAmount}
                onChange={e => handleChangeAmount(e)}
                placeholder={t('refillForm.placeholder.amount')}
              />
              {currentCard?.minimalAmount ? (
                <div className="withdraw-balance-form__main--form-item-hint">
                  {t('refillForm.input.amountMinimal')} $
                  {currentCard?.minimalAmount}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="withdraw-balance-form__footer">
        <div className="withdraw-balance-form__footer--value">
          <h3>
            {t('total')}: $ {Number(refillAmount).toFixed(2)}
          </h3>
          {currentCard?.tax ? (
            <span>
              {t('commission')}: ${' '}
              {currentCard?.taxType === ERefillTaxType.Percentage
                ? ((Number(refillAmount) / 100) * currentCard.tax).toFixed(2)
                : currentCard?.tax}
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="withdraw-balance-form__footer--actions">
          <Button
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            disabled={disabledSubmit}
            loading={isLoadingSendRefill}
            onClick={() => {
              onConfirm();
            }}
          >
            {t('increaseBalance')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddBalanceForm };
