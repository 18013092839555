import { useQuery } from '@tanstack/react-query';

//types
// services
import { GET_REFILL_COUNTRIES } from 'api/wallet';

export const useQueryFetchRefillCountries = () => {
  return useQuery(['useGetRefillCountries'], () => GET_REFILL_COUNTRIES(), {
    refetchOnMount: () => 'always',
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err
        ? err?.message
        : 'Error while fetching withdraw methods';
      console.error(message);
      return message;
    },
  });
};
