import React, { useEffect, useMemo, useRef } from 'react';
import { useWindowSize } from 'react-use';
import { Input, Form } from 'antd';
import { MEDIA } from '../../../../../utils/constants';
import { formatFloat, formatPrice } from '../../../../../utils/commons';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// core
// utils
// locales
// store
import { useAppStore } from 'store';
// types
import { IPropsType } from './types';
import { EInputSizeType } from 'types/units';
import { EGameType } from 'types/models';
import { EFloatRangeSizeType } from '../../../../atoms/FloatRange/types';
// components(atoms)
import { FloatRange } from 'components/atoms';
import { StickersBox } from 'components/features';

// styles
import '../index.scss';

// data

// func

const OfferTradeModalCard = ({
  data,
  defaultPrice,
  price,
  className,
  onPriceChange,
}: IPropsType) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { gameType, commission_percentage } = useAppStore();

  const [form] = Form.useForm();
  const staticPrice = useRef<any>(defaultPrice);
  const maxAutoBuyPrice = useRef<any>(data.maxAutoBuyPrice);
  const controlsSchema = yup.object().shape({
    price: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validations.amount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validations.required'))
      .min(
        maxAutoBuyPrice.current > 0
          ? (maxAutoBuyPrice.current + maxAutoBuyPrice.current * 0.01).toFixed(
              2,
            )
          : 0.03,
        t('validations.minValue', {
          min:
            maxAutoBuyPrice.current > 0
              ? (
                  maxAutoBuyPrice.current +
                  maxAutoBuyPrice.current * 0.01
                ).toFixed(2)
              : 0.03,
        }),
      )
      .max(
        staticPrice.current - staticPrice.current * 0.01,
        t('validations.maxValue', {
          max: (staticPrice.current - staticPrice.current * 0.01).toFixed(2),
        }),
      )
      .typeError(t('validations.isNumeric')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await controlsSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    onPriceChange(price - 0.01);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Stickers = useMemo(() => {
    let collection =
      gameType === EGameType.CSGO
        ? [...data.stickers, ...data.keychains]
        : data.gems;
    if (!collection.length) return null;
    return (
      <StickersBox
        collection={collection}
        sku={data.sku}
        gameType={gameType}
        containerClassName={'trade-modal-card__stickers'}
        itemClassName={'trade-modal-card__sticker'}
      />
    );
  }, [data, gameType]);

  const Prices = useMemo(() => {
    let { minMarketPrice, minAutoBuyPrice } = data;
    return (
      <div className="trade-modal-card__prices">
        <div className="trade-modal-card__price">
          {t('minPrice')}
          <span>
            {minMarketPrice ? `$${formatPrice(minMarketPrice)}` : '$0.01'}
          </span>
        </div>
        <div className="trade-modal-card__price">
          {t('autobuy')}
          <span>
            {minAutoBuyPrice ? `$${formatPrice(minAutoBuyPrice)}` : '---'}
          </span>
        </div>
      </div>
    );
  }, [data, t]);

  return (
    <div className={'trade-modal-card' + (className ? ` ${className}` : '')}>
      <div className="trade-modal-card__left">
        <div className="trade-modal-card__img">
          <img
            src={data.preview?.length ? data.preview : data.image}
            alt={data.name}
          />
        </div>
        <div className="trade-modal-card__info">
          <div className="trade-modal-card__title">{data.marketName.first}</div>
          <div className="trade-modal-card__label">
            {data.marketName.second}
          </div>
          {gameType === EGameType.CSGO ? (
            <>
              {data.float > 0 && (
                <div className="trade-modal-card__float">
                  <div className="trade-modal-card__float-grid">
                    {data.shortenExterior}
                    <span>{formatFloat(data.float)}</span>
                  </div>
                  <FloatRange
                    isTooltip={!!data.float}
                    size={EFloatRangeSizeType.Small}
                    maxValue={data.float ? formatFloat(data.float) : 0}
                  />
                </div>
              )}
              {Stickers}
            </>
          ) : (
            <>
              <div className="trade-modal-card__label">{data.slot}</div>
              {Stickers}
            </>
          )}
          {width > MEDIA.M768 && width <= MEDIA.M1280 && Prices}
        </div>
      </div>

      <div className="trade-modal-card__right">
        {(width <= MEDIA.M768 || width > MEDIA.M1280) && Prices}
        <div className="trade-modal-card__divider" />
        <Form
          form={form}
          layout="vertical"
          className="trade-modal-card__form"
          initialValues={{
            defaultPrice: formatPrice(defaultPrice, 2, false),
            price: formatPrice(price - 0.01, 2, false),
          }}
        >
          <div className="trade-modal-card__input">
            <label>{t('currentPrice')}</label>
            <Form.Item className="ant-form-item--flat" name={'defaultPrice'}>
              <Input
                size={EInputSizeType.Middle}
                prefix={'$'}
                readOnly={true}
              />
            </Form.Item>
          </div>
          <div className="trade-modal-card__input">
            <label>{t('newPrice')}</label>
            <Form.Item
              className="ant-form-item--flat"
              name={'price'}
              /*@ts-ignore*/
              rules={[yupSync]}
            >
              <Input
                size={EInputSizeType.Middle}
                prefix={'$'}
                onChange={({ target: { value } }) => {
                  let numberValue = Number(value) ? Number(value) : 0;
                  onPriceChange(numberValue);
                }}
              />
            </Form.Item>

            <div className="trade-modal-card__input-note">
              {t('minPercentage')}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export { OfferTradeModalCard };
