// core
import React, { useEffect, useState } from 'react';

//store
import { useAppStore } from 'store';

//hooks

// utils

// locales

// types
import { ELoaderTypeType } from '../../../components/atoms/AppLoader/types';
// components(atoms)
import { HomeSEO } from './SEO';
import { HomePromo } from './HomePromo';
import { HomeMarquee } from './HomeMarquee';
import { HomeAssortment } from './HomeAssortment';
import { HomeP2P } from './HomeP2P';
import { HomeSecurity } from './HomeSecurity';
import { HomeFaq } from './HomeFaq';
import { HomeCta } from './HomeCta';
import { AppLoader } from 'components/atoms';

// styles
import './index.scss';
import { ScrollToTop } from '../../../components/atoms/ScrollToTop';

//tempo

// func
const Home = () => {
  const { variables } = useAppStore();

  const [isLoadedHomeMarquee, setIsLoadedHomeMarquee] = useState(false);
  const [isLoadedHomeFaq, setIsLoadedHomeFaq] = useState(false);

  useEffect(() => {
    if (variables?.length && isLoadedHomeMarquee && isLoadedHomeFaq) {
      window['prerenderReady'] = true;
    }
  }, [variables, isLoadedHomeMarquee, isLoadedHomeFaq]);

  return (
    <>
      <HomeSEO />
      {variables?.length ? (
        <div className="home">
          <HomePromo />
          <HomeMarquee
            onLoaded={() => {
              setIsLoadedHomeMarquee(true);
            }}
          />
          {/*<HomeAssortment />*/}
          <HomeP2P />
          <HomeSecurity />
          <HomeFaq
            onLoaded={() => {
              setIsLoadedHomeFaq(true);
            }}
          />
          <HomeCta />
          <ScrollToTop />
        </div>
      ) : (
        <AppLoader type={ELoaderTypeType.Global} />
      )}
    </>
  );
};

export { Home };
